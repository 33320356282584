import React, { useEffect } from "react";
import useFetch from "../api/useFetch";
import { useState } from "react";
import Table from "./Table";
import Loading from "./Loading";
import { useParams } from 'react-router-dom';
import funcs from "../utils/utils";

const Payroll = () => {
  const { id } = useParams();
  const { result: payroll, isLoading: payrollLoading, error: payrollError } = useFetch(`/payroll/${id}`, []);
  const tableHeader = [{ content: "Technician", size: "md", isLink: false}, { content: "Date", size: "md", isLink: false}, { content: "Client", size: "md", isLink: false}, { content: "Week", size: "md", isLink: false}, { content: "Year", size: "md", isLink: false}, { content: "Service Payout", size: "md", isLink: false}, { content: "Technician Tip", size: "md", isLink: false}];
  const [tableContent, setTableContent] = useState(null);

  useEffect(() => {
    if (payroll !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTableContent(
        payroll.items.sort((a,b) => new Date(a.appointment.date_time) - new Date(b.appointment.date_time)).map((item) => [
          item.appointment.technician.person.first_name + " " + item.appointment.technician.person.last_name,
          funcs.displayDate(item.appointment.date_time),
          item.appointment.client.person.first_name + " " + item.appointment.client.person.last_name,
          item.appointment.week,
          item.appointment.year,
          item.appointment.technician_payout,
          item.appointment.technician_tip,
        ])
      );
    }
  }, [payroll]);
  

  return (
    <>
        <div div className="list">
        <div className="title">
          Payroll
        </div>
        {payroll && <Table tableHeader={tableHeader} tableContent={tableContent} />}
      {payrollLoading && <Loading />}
      {payrollError && <>{payrollError}</>}
      </div>
    </>
  );
};

export default Payroll;
